// src/alpha-signup.js
import React, { useState } from 'react';
import './css/alpha-signup.css'; // Ensure you create this CSS file

const AlphaSignup = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Here, add the code to submit the email to Google Sheets.
        // This example won't cover the Google Sheets API integration directly.
        console.log('Submitted email:', email);
    };

    return (
        <div className="alpha-signup-container">
            <header>
                <img src="/path-to-your-logo.png" alt="Logo" className="logo" />
            </header>
            <main>
                <h1>Welcome to Ijuro</h1>
                <p>Subscribe to stay updated.</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="email-input"
                    />
                    <button type="submit" className="subscribe-button">Subscribe</button>
                </form>
                <div className="video-container">
                    <iframe
                        src="https://www.youtube.com/embed/2wXfyDAQ6pU"
                        title="Get to know Ijuro"
                        allowFullScreen
                    ></iframe>
                </div>

            </main>
            <footer>
                <a href="/faq">Ijuro FAQ</a>
                {/* Add more links as needed */}
            </footer>
        </div>
    );
};

export default AlphaSignup;
