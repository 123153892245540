import React from 'react';
import Header from './components/Header';
import Chat from './components/Chat';
import InfoSection from './components/InfoSection';
import Footer from './components/Footer';
import AlphaSignup from './alpha-signup'; // Adjust the path as necessary


function App() {
    return (
        <div>
            <AlphaSignup />
        </div>
    );
}

export default App;
